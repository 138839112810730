import React from 'react';
import { withUserContext } from '../../contexts/UserContext';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TableComponent from '../../components/SharedComponents/Table';
import TablePagination from '@material-ui/core/TablePagination';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import {
    queueFilters, configObjectSubmissions, configObjectProjects,
    configObjectDeals, configObjectWeekendRead, tabList, projectFiltersSearchJson, dealsFilterSearchJson,
    weekendReadFilterSearchJson, projectAndDeal, submissionFiltersSearchJson, confidentialFlag, activeOrInactive, selectOptionsSubmissions, selectOptionsProjects, compareOptions
} from './config';
import { handlePaginationList, updateTableSortConfigObject } from '../../Common/TableHelper';
import "./ScripterLandingPage.scss";
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import BasicCheckbox from '../SharedComponents/BasicCheckbox/BasicCheckbox';
import MessageModal from '../SharedComponents/MessageModal';
import SelectField from '../SharedComponents/SelectField/SelectField';
import { getFormattedDate } from '../../Common/Helper'
import ScripterService from '../../services/service';
import * as Constants from '../../constants/constants';
import CircularProgress from "@material-ui/core/CircularProgress";
import Loader from '../../Common/Loader/Loader';
import { withRouter } from 'react-router-dom';
export default withRouter(withUserContext(class ScripterLandingPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user_name: localStorage.getItem("user_full_name"),
            divisionName: null,
            tabValue: 0,
            tabName: tabList[0].label,
            listBackUp: [],
            list: [],
            page: 1,
            tabList: JSON.parse(JSON.stringify([...tabList])),
            rowsPerPage: 10,
            tenant_id: null,
            currentList: [],
            submissionFilters: JSON.parse(JSON.stringify({ ...submissionFiltersSearchJson })),
            projectFilters: JSON.parse(JSON.stringify({ ...projectFiltersSearchJson })),
            dealsFilters: JSON.parse(JSON.stringify({ ...dealsFilterSearchJson })),
            weekendReadFilters: JSON.parse(JSON.stringify({ ...weekendReadFilterSearchJson })),
            submissionsData: [],
            projectData: [],
            dealsData: [],
            weekendReadData: [],
            count: 0,
            config: JSON.parse(JSON.stringify({ ...configObjectSubmissions })),
            isFetchingData: false,
            isFetchingSubmissionData:false,
            queueList: [],
            confirmDelete: false,
            confirmDeleteMsg: 'Are you sure you want to delete from Queue?',
            maxColErrorMessage: 'Max ten columns are allowed for selection',
            minColErrorMessage: 'Min two columns should be selected',
            showMaxCols: 'Max Columns Alert',
            showMinColsAlert: 'Min Columns Alert',
            selectedQueue: [],
            projectAndDeal: projectAndDeal,
            modifyTabOptions: () => this.modifyTabOptions(),
            check: null,
            tabChange: false,
            selectOptions: [],
            showCols: false,
            showMinCols: false,
            isLoadingQueueData: false,
            sortBy: "Value",
            isSortByAsc: true,
            sortCount: 0,
            queueFilterId: "SUBMISSIONS",
            submittedToGroupName: null
        }
    }

    componentDidMount() {       
        this.getGroups();
    }
    componentDidUpdate(prevProps, prevState) {
        if (window.location.pathname === "/") {
            this.handleTabChange(null, this.state.tabList[0].index);
        } else if (window.location.pathname.slice(1) !== this.state.tabName.replace(/ /g, "")) {
            let newTabList = [...this.state.tabList];
            let newTabValue = newTabList?.filter((item, index) => item.label.replace(/ /g, "") === window.location.pathname.slice(1).replace(/ /g, ""))[0]?.index;
            this.handleTabChange(null, newTabValue);
        } 
    }

    a11yProps = (index) => {
        return {
            id: `horizontal-tab-${index}`,
            'aria-controls': `horizontal-tabpanel-${index}`
        };
    }
    
    modifyTabOptions = () => {
        this.setState({ tenant_id: this.props?.userContext?.active_tenant?.tenant_id })
        if (this.props?.userContext?.active_tenant?.tenant_id) {
            let permissions = this.props?.userContext?.active_tenant?.permissions;
            let projects = this.props?.userContext?.active_tenant?.projects;
            let submissions = this.props?.userContext?.active_tenant?.submissions;
            let tabList = JSON.parse(JSON.stringify([...this.state.tabList]))

            if (!permissions?.canViewConfidentialSubmissions && !permissions.canViewNonConfidentialSubmissions && !submissions.length > 0) {

                tabList = tabList.filter(item => item.index !== 0)
            }
            if (!permissions?.canViewProjects && !permissions.canViewConfidentialProject && !projects.length > 0) {
                tabList = tabList.filter(item => item.index !== 1)
            }
            if (!permissions?.canViewDeal || 
                (this.props?.userContext?.user_profile?.is_project_executive == 0 && !permissions?.canViewProjectDeals)
            ) {
                tabList = tabList.filter(item => item.index !== 2)
            }
            if (!permissions?.canViewWeekendRead) {
                tabList = tabList.filter(item => item.index !== 3)
            }
            this.setState({ tabList: tabList }, () => this.setTabName(this.state.tabList))
        }
    }

    setTabName = (tabList) => {
        let tabName = this.state.tabName;
        let config = this.state.config;
        tabName = tabList[0].label;
        config = (tabName === "Submissions" ? JSON.parse(JSON.stringify({ ...configObjectSubmissions })) : tabName === "Projects" ? JSON.parse(JSON.stringify({ ...configObjectProjects })) : tabName === "Deals" ? JSON.parse(JSON.stringify({ ...configObjectDeals })) : tabName === "Weekend Read" ? JSON.parse(JSON.stringify({ ...configObjectWeekendRead })) : null);
        this.setState({
            config,
            tabName
        }, () => { 
            this.pickMethod(this.state.tabName), 
            this.getDropDownValues(this.state.config, null), 
            this.getSelectOptions(this.state.tabName),
            this.getDivisionValues(this.state.config, ''); 
        })
    }


    static getDerivedStateFromProps(props, state) {
        if (props?.userContext?.active_tenant?.tenant_id !== state.tenant_id) {
            state.modifyTabOptions();
            return {
                tenant_id: props?.userContext?.active_tenant?.tenant_id
            }
        }
        return null;
    }

    getSelectOptions = (tabName) => {
        let selectOptionsChange = this.state.selectOptions;
        switch (tabName) {
            case "Submissions":
                selectOptionsChange = selectOptionsSubmissions
                break;
            case "Projects":
                selectOptionsChange = selectOptionsProjects
                break;

        }
        this.setState({
            selectOptions: selectOptionsChange
        })
    }

    clearTabChange = () => {
        this.setState({
            tabChange: false
        })
    }

    getDivisionValues = (config, v) => {
        config?.headings?.map(data => {
            if (data.inputType === "dropdown" && data.dataNode === "division_name") {
                ScripterService.getData(Constants.scripterServiceBaseUrl +
                    `/entitySearch?entity=${data.entityType}&searchString=${v}`,
                    this.props.userContext.active_tenant.tenant_id)
                    .then(response => {
                        let formattedList = response.data?.map(item => ({ value: Object.values(item)[0], label: Object.values(item)[1], is_active: item.is_active }));
                        
                        this.setState({ [data.selectOptions]: formattedList });
                    },
                        (err) => {
                            console.log("Error in fetching entities:", err)
                        })
            }
        })
    }

    getDropDownValues = (config, v) => {
        config?.headings?.map(data => {
            if (data.inputType === "dropdown" || data.inputType === "daterange") {
                if (data.tableName != 'none') {
                    ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=' + data.tableName, this.props.userContext?.active_tenant?.tenant_id)
                        .then(response => {
                            let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
                            this.setState({ [data.selectOptions]: formattedList });
                        },
                            (err) => {

                            })
                } else if (data.tableName === 'none') {
                    if (data.dataNode === "status") {
                        this.setState({ [data.selectOptions]: activeOrInactive });
                    } else if (data.dataNode === "is_confidential") {
                        this.setState({ [data.selectOptions]: confidentialFlag });
                    } else if (data.dataNode === "received_date" || data.dataNode === "due_date" || data.dataNode === "draft_date" || data.dataNode === "wrap_date" || data.dataNode === "prod_start_date" || data.dataNode === "release_date" || data.dataNode === "rights_lapse_date" || data.dataNode === "memo_date" || data.dataNode === "ammendments" || data.dataNode === "weekend_read_date") {
                        this.setState({ [data.selectOptions]: compareOptions });
                    }
                }
            }
        })
    }

    getCheckBoxValues = (values) => {
        let checked = this.state.check;
        checked = values;
        let filters = (this.state.tabName === "Submissions" ? this.state.submissionFilters : this.state.tabName === "Projects" ? this.state.projectFilters : this.state.tabName === "Deals" ? this.state.dealsFilters : this.state.tabName === "Weekend Read" ? this.state.weekendReadFilters : null)
        values == 0 || values == null ? filters["confidential_flag"] = null : values == 1 ? filters["confidential_flag"] = 1 : values == 2 ? filters["confidential_flag"] = 0 : null;
        this.setState({
            check: checked,
            submissionFilters: this.state.tabName === "Submissions" ? filters : this.state.submissionFilters,
            projectFilters: this.state.tabName === "Projects" ? filters : this.state.projectFilters,
            dealsFilters: this.state.tabName === "Deals" ? filters : this.state.dealsFilters,
            weekendReadFilters: this.state.tabName === "Weekend Read" ? filters : this.state.weekendReadFilters
        }, () => this.pickMethod(this.state.tabName))
    }
    formatTalentRepName = (str) => {
        var i, frags = str?.split('_');
        for (i = 0; i < frags?.length; i++) {
            frags[i] = frags[i]?.charAt(0)?.toUpperCase() + frags[i]?.slice(1);
        }
        return frags?.join(' ');
    }
    
    getAutoCompleteValues = (config, v) => {
        if (config.inputType === "autocomplete") {
            if (config.entityType != 'none') {
                let groupName = this.state.submittedToGroupName;
                let url= config.headingLabel==='Analyst' ?`/entitySearch?entity=${config.entityType}&permissionName="Assignable (and view submission)/Submit Coverages"&isinacUser=1&searchString=${v}` : config.headingLabel==='Submitted To' ? `/entitySearch?entity=${config.entityType}&groupName=${groupName}&isinacUser=1&searchString=${v}` : `/entitySearch?entity=${config.entityType}&searchString=${v}`
                ScripterService.getData(Constants.scripterServiceBaseUrl +
                    url,
                    this.props.userContext.active_tenant.tenant_id)
                    .then(response => {
                        let formattedList = [];
                        if (config.entityType === 'SUBMISSION') {
                            formattedList = response.data?.map(item => ({ value: item.submission_id, label: item.title + (item.received_date ? ` - Rcvd. ${item.received_date}` : ''), submissionRcvdDate: item.received_date, is_active: item.is_active }));
                            formattedList.sort(function (a, b) {
                                return new Date(b.submissionRcvdDate) - new Date(a.submissionRcvdDate);
                            });
                        }
                        else if(config.entityType === 'TALENT_REP'){
                        formattedList = response?.data?.map(item => ({ value: item.id, label: item.name + ' - ' + this.formatTalentRepName(item?.type), type: item.type, company: item?.company, company_id: item?.company_id }));
                         this.setState({[config.selectOptions] : formattedList})
                        }else if(config.entityType === 'TALENT'){
                            formattedList = response.data?.map(item => ({ value: Object.values(item)[0], label: Object.values(item)[1], writer_team: Object.values(item)[3] }));
                            this.setState({ [config.selectOptions]: formattedList });
                        }
                        else{
                            formattedList = response.data?.map(item => ({ value: Object.values(item)[0], label: Object.values(item)[1], is_active: item.is_active }));
                            this.setState({ [config.selectOptions]: formattedList });
                        }
                         
                    },
                        (err) => {
                            console.log("Error in fetching entities:", err)
                        })
            }
        }
    }

    pickMethod = (tabName) => {
        switch (tabName) {
            case "Submissions":
                this.getSubmissionsData();
                break;
            case "Projects":
                this.getProjectsData();
                break;
            case "Deals":
                this.getDealsData();
                break;
            case "Weekend Read":
                this.getWeekendReadData();
                break;
            case "My Queue":
                this.getQueueList('SUBMISSIONS');
                break;
        }
    }

    handleTabChange = (event, value, x) => {
        let tabListNew = this.state.tabList;
        let getTabName = tabListNew.filter((item, index) => item?.index === value)?.[0]?.label;
        if (getTabName) {
            this.props.history.push(`/${getTabName?.replace(/ /g, "")}`);

            let tabChangeFlag = this.state.tabChange
            tabChangeFlag = true;
            let check = this.state.check
            check = null
            let tabList = (getTabName === "Submissions" ? this.state.submissionsData : getTabName === "Projects" ? this.state.projectData : getTabName === "Deals" ? this.state.dealsData : getTabName === "Weekend Read" ? this.state.weekendReadData : null);
            let configUpdate = (getTabName === "Submissions" ? JSON.parse(JSON.stringify({ ...configObjectSubmissions })) : getTabName === "Projects" ? JSON.parse(JSON.stringify({ ...configObjectProjects })) : getTabName === "Deals" ? JSON.parse(JSON.stringify({ ...configObjectDeals })) : getTabName === "Weekend Read" ? JSON.parse(JSON.stringify({ ...configObjectWeekendRead })) : null);
            let filtersCopy = (getTabName === "Submissions" ? JSON.parse(JSON.stringify({ ...submissionFiltersSearchJson })) : getTabName === "Projects" ? JSON.parse(JSON.stringify({ ...projectFiltersSearchJson })) : getTabName === "Deals" ? JSON.parse(JSON.stringify({ ...dealsFilterSearchJson })) : getTabName === "Weekend Read" ? JSON.parse(JSON.stringify({ ...weekendReadFilterSearchJson })) : null)
            this.setState({
                sortBy: "Value",
                isSortByAsc: true,
                sortCount: 0,
                tabName: getTabName,
                check,
                tabChange: tabChangeFlag,
                page: 1,
                rowsPerPage: 10,
                tabValue: value,
                list: (getTabName === "Submissions" || getTabName === "Projects" || getTabName === "Deals" || getTabName === "Weekend Read") ? tabList : handlePaginationList(0, this.state.rowsPerPage, tabList),
                listBackUp: tabList,
                currentList: (getTabName === "Submissions" || getTabName === "Projects" || getTabName === "Deals" || getTabName === "Weekend Read") ? this.state.count : tabList,
                searchTerm: null,
                config: configUpdate,
                submissionFilters: getTabName === "Submissions" ? filtersCopy : this.state.submissionFilters,
                projectFilters: getTabName === "Projects" ? filtersCopy : this.state.projectFilters,
                dealsFilters: getTabName === "Deals" ? filtersCopy : this.state.dealsFilters,
                weekendReadFilters: getTabName === "Weekend Read" ? filtersCopy : this.state.weekendReadFilters,
                showHistoricalData: false
            }, () => { 
                this.getDropDownValues(this.state.config, null), 
                this.pickMethod(this.state.tabName), 
                this.getSelectOptions(this.state.tabName),
                this.getDivisionValues(this.state.config, '');
            });
        }
    }

    //on specific column check and uncheck
    onColumnCheck = (element, index, checked) => {
        let config = { ...this.state.config };
        let count = config.headings.filter(item => item.isColumn === true).length;
        if (count > 9 && checked) {
            this.setState({ showCols: true })
            // alert('Max ten columns are allowed for selection')
        } else if (count <= 2 && !checked) {
            this.setState({ showMinCols: true })
            // alert('Min two columns are allowed for selection')
        }
        else {
            //Headings filter will changes here
            config.headings[index].isColumn = checked;
            if (count === 9 && this.state.tabName === "Submissions" && checked) {
                if (config.headings[0]?.dataNode === "akaTitle")  {
                    config.headings[0].width = "100%";
                }
            }
            if (this.state.tabName === "Submissions" || this.state.tabName === "Projects" || this.state.tabName === "Weekend Read") {
                let filteredColumns = config.headings.filter(item => item.width === "100%");
                if (checked && filteredColumns?.length < 10) {
                    config.headings[filteredColumns?.length].width = "100%";
                }
            }
            this.setState({ config: config })
        }
    }

    handleShowHistoricalData = (event) => {
        let submissionFilters = JSON.parse(JSON.stringify({ ...this.state.submissionFilters }));
        submissionFilters['show_historical_data'] = event.target.checked ? 1 : 0;
        submissionFilters['page_count'] = this.state.rowsPerPage;
        this.setState({ 
            submissionFilters: submissionFilters, 
            showHistoricalData: event.target.checked }, () => {
                this.pickMethod(this.state.tabName)
        })
    }

    applyFilterLandingPage = (filterValues, filtersNone, dropDownValues) => {
        let config = this.state.config;
        let filtersCopy = (this.state.tabName === "Submissions" ? JSON.parse(JSON.stringify({ ...submissionFiltersSearchJson })) : this.state.tabName === "Projects" ? JSON.parse(JSON.stringify({ ...projectFiltersSearchJson })) : this.state.tabName === "Deals" ? JSON.parse(JSON.stringify({ ...dealsFilterSearchJson })) : this.state.tabName === "Weekend Read" ? JSON.parse(JSON.stringify({ ...weekendReadFilterSearchJson })) : null)
        if (!filtersNone) {
            filterValues.map((filter) => {
                var keyValue = filter.split(":");
                var name = keyValue[0].trim();
                var value = keyValue[1].trim().toLowerCase();
                var valueType = keyValue[1].trim()?.includes('-') ? keyValue[1].trim()?.split('-')[1].trim().toLowerCase() : null;
                var date = keyValue[2]?.trim();
                var id = dropDownValues.length !== 0 ? dropDownValues?.filter((item) => item.label.trim().toLowerCase() === value)?.length> 1 ? dropDownValues?.filter((item) => item.label.trim().toLowerCase() === value)[(dropDownValues?.filter((item) => item.label.trim().toLowerCase() === value)?.length)-1]?.value : dropDownValues?.filter((item) => item.label.trim().toLowerCase() === value)[0]?.value : null;
                var writer_team = this.state?.tabName === "Submissions" && dropDownValues?.length !== 0 ? 
                (dropDownValues?.find(item => item?.value === id && item?.label.trim().toLowerCase() === value)?.writer_team || 0 ) : 
                0;
                var index = -1; // Object.keys(config?.headings).indexOf((item) => config?.headings[item].headingLabel == name);
                var searchKey = "";
                var dateOperator = "";
                var orderBy = "";
                var type = ""
                Object.keys(config?.headings).map((item) => {
                    if (config?.headings[item].headingLabel == name) {
                        index = item;
                        if(valueType){
                            valueType === 'talent'? searchKey = config?.headings[item].searchJson[0]:
                            valueType === 'talent group'? searchKey = config?.headings[item].searchJson[1]:
                            searchKey = config?.headings[item].searchJson;
                        }else{
                            searchKey = config?.headings[item].searchJson;
                        }
                        dateOperator = config?.headings[item]?.operator;
                        type = config?.headings[item].inputType;
                        orderBy = config?.headings[item].headingLabel;
                        return;
                    }
                });
                if (type === "dropdown" || type === "autocomplete") {
                    if (searchKey === "author_search_ids" && this.state.tabName === "Submissions"){
                        filtersCopy["writer_team"] = writer_team ;
                        filtersCopy[searchKey] = id + "";
                        
                    }
                    filtersCopy[searchKey] = id + "";
                } else if (type === "daterange") {
                    filtersCopy[searchKey] = date
                    filtersCopy[dateOperator] = value;
                }
                else {
                    filtersCopy[searchKey] = value;
                }
                filtersCopy["page_count"] = this.state.rowsPerPage;
                filtersCopy["show_historical_data"] = 1;
                this.setState({
                    submissionFilters: this.state.tabName === "Submissions" ? filtersCopy : this.state.submissionFilters,
                    projectFilters: this.state.tabName === "Projects" ? filtersCopy : this.state.projectFilters,
                    dealsFilters: this.state.tabName === "Deals" ? filtersCopy : this.state.dealsFilters,
                    weekendReadFilters: this.state.tabName === "Weekend Read" ? filtersCopy : this.state.weekendReadFilters,
                    showHistoricalData: true
                }, () => { this.handleChangePage(null, 0) })
            })
        }
        else {
            let filtersJson = (this.state.tabName === "Submissions" ? JSON.parse(JSON.stringify({ ...submissionFiltersSearchJson })) : this.state.tabName === "Projects" ? JSON.parse(JSON.stringify({ ...projectFiltersSearchJson })) : this.state.tabName === "Deals" ? JSON.parse(JSON.stringify({ ...dealsFilterSearchJson })) : this.state.tabName === "Weekend Read" ? JSON.parse(JSON.stringify({ ...weekendReadFilterSearchJson })) : null)
            filtersJson["page_count"] = this.state.rowsPerPage;
            filtersJson["show_historical_data"] = 0;
            this.setState({
                submissionFilters: this.state.tabName === "Submissions" ? filtersJson : this.state.submissionFilters,
                projectFilters: this.state.tabName === "Projects" ? filtersJson : this.state.projectFilters,
                dealsFilters: this.state.tabName === "Deals" ? filtersJson : this.state.dealsFilters,
                weekendReadFilters: this.state.tabName === "Weekend Read" ? filtersJson : this.state.weekendReadFilters,
                showHistoricalData: false
            }, () => { this.handleChangePage(null, 0) })
        }
    }

    getSubmissionsData = () => {
        this.setState({ isFetchingSubmissionData: true });
        let postJson = {...this.state.submissionFilters};
        postJson["show_historical_data"] = this.state.showHistoricalData ? 1 : 0;
        let load = {
            "payload": 'dashboardSubmissions',
            "input": btoa(JSON.stringify(postJson)),
            "encryptionType": 1,
            "tenantId": this.props?.userContext?.active_tenant?.tenant_id,
        }
        ScripterService.getDataWS(load)
        .then(webSocketConn => {
            webSocketConn.onmessage = (e) => {
                let response = JSON.parse(e.data)
                if(response?.statusCode == 200) {
                    let y = JSON.parse(response.body)?.data;
                    let x = y.map(item => { item.akaTitle = ((item.aka != null && item.aka != "null") ? item.title + " + " + item.aka : item.title);
                                            item.akaTitleShowRed = item?.is_confidential ===1 || item?.is_un_official === 1 
                                            item.akaTitleShowBold = item?.project_title ?  1 : 0 
                    return item; })
                    let r = x.map(item => { item.is_confidential = (item.is_confidential == 1 ? "Confidential" : "Non-Confidential"); return item; })
                    let count = JSON.parse(response.body)?.count;
                    this.setState({ submissionsData: r, count: count })
                    if (this.state.tabName === "Submissions") {
                        this.setState({
                            list: r,
                            currentList: count,
                            listBackUp: r,
                            isFetchingSubmissionData: false
                        })
                    }
                } else {
                    this.setState({ isFetchingSubmissionData: false });
                }
            }
        }, (err) => {
            this.setState({ isFetchingSubmissionData: false });
            console.log("Error in fetching all sets", err)
        })
    }

    getProjectsData = () => {
        this.setState({ isFetchingData: true });
        let load = {
            "payload": 'dashboardProjects',
            "input": btoa(JSON.stringify(this.state.projectFilters)),
            "encryptionType": 1,
            "tenantId": this.props?.userContext?.active_tenant?.tenant_id,
        }
        ScripterService.getDataWS(load)
        .then(webSocketConn => {
            webSocketConn.onmessage = (e) => {
                let response = JSON.parse(e.data)
                if(response?.statusCode == 200) {
                    let y = JSON.parse(response.body)?.data;
                    let x = y.map(item => { item.akaTitle = (item.aka != null ? item.project_title + " + " + item.aka : item.project_title);
                                            item.akaTitleShowRed = item?.is_confidential === 1  
                    return item; })
                    let r = x.map(item => { item.is_confidential = (item.is_confidential == 1 ? "Confidential" : "Non-Confidential"); return item; })
                    let count = JSON.parse(response.body)?.count
                    this.setState({ projectData: r, count: count, isFetchingData: false })
                    if (this.state.tabName === "Projects") {
                        this.setState({
                            list: r,
                            currentList: count
                        })
                    }
                } else {
                    this.setState({ isFetchingData: false });
                }
            }
        }, (err) => {
            this.setState({ isFetchingData: false });
            console.log("Error in fetching all sets", err)
        })
    }

    getDealsData = () => {
        this.setState({ isFetchingData: true });
        let load = {
            "payload": 'dashboardDeals',
            "input": btoa(JSON.stringify(this.state.dealsFilters)),
            "encryptionType": 1,
            "tenantId": this.props?.userContext?.active_tenant?.tenant_id,
        }
        ScripterService.getDataWS(load)
        .then(webSocketConn => {
            webSocketConn.onmessage = (e) => {
                let response = JSON.parse(e.data)
                if(response?.statusCode == 200) {
                    let r = JSON.parse(response.body)?.data;
                    r = r.map(item => { 
                        item.project_title = (item.project_aka != null ? item.project_title + " + " + item.project_aka : item.project_title);
                        return item; 
                    })
                    let count = JSON.parse(response.body)?.count
                    this.setState({ dealsData: r, count: count, isFetchingData: false })
                    if (this.state.tabName === "Deals") {
                        this.setState({
                            list: r,
                            currentList: count
                        })
                    }
                } else {
                    this.setState({ isFetchingData: false });
                }
            }
        }, (err) => {
            this.setState({ isFetchingData: false });
            console.log("Error in fetching all sets", err)
        })

    }

    getWeekendReadData = () => {
        this.setState({ isFetchingData: true });
        let load = {
            "payload": 'dashboardWeekendReads',
            "input": btoa(JSON.stringify(this.state.weekendReadFilters)),
            "encryptionType": 1,
            "tenantId": this.props?.userContext?.active_tenant?.tenant_id,
        }
        ScripterService.getDataWS(load)
        .then(webSocketConn => {
            webSocketConn.onmessage = (e) => {
                let response = JSON.parse(e.data)
                if(response?.statusCode == 200) {
                    let r = JSON.parse(response.body)?.data;
                    r?.map(item => {
                        if (item?.notes) {
                            item.notes = (item?.notes && item?.notes?.length > 300) ?
                            (item?.notes?.slice(0, 300) + "...") : item?.notes
                        }
                        return item;
                    })
                    let count = JSON.parse(response.body)?.count
                    
                    if (this.state.tabName === "Weekend Read") {
                        this.setState({
                            list: r,
                            currentList: count
                        })
                    }
                    this.setState({ weekendReadData: r, count: count, isFetchingData: false })
                } else {
                    this.setState({ isFetchingData: false });
                }
            }
        }, (err) => {
            this.setState({ isFetchingData: false });
            console.log("Error in fetching all sets", err)
        })
    }

    handleChangeRowsPerPage = (event) => {
        let filters = (this.state.tabName === "Submissions" ? this.state.submissionFilters : this.state.tabName === "Projects" ? this.state.projectFilters : this.state.tabName === "Deals" ? this.state.dealsFilters : this.state.tabName === "Weekend Read" ? this.state.weekendReadFilters : null)
        filters["page_count"] = event.target.value;
        filters["page_no"] = 1
        this.setState({
            rowsPerPage: event.target.value, page: 1,
            submissionFilters: this.state.tabName === "Submissions" ? filters : this.state.submissionFilters,
            projectFilters: this.state.tabName === "Projects" ? filters : this.state.projectFilters,
            dealsFilters: this.state.tabName === "Deals" ? filters : this.state.dealsFilters,
            weekendReadFilters: this.state.tabName === "Weekend Read" ? filters : this.state.weekendReadFilters
        }, () => {
            this.pickMethod(this.state.tabName)
        });
    }

    handleChangePage = (event, newPage) => {
        let filters = (this.state.tabName === "Submissions" ? this.state.submissionFilters : this.state.tabName === "Projects" ? this.state.projectFilters : this.state.tabName === "Deals" ? this.state.dealsFilters : this.state.tabName === "Weekend Read" ? this.state.weekendReadFilters : null)
        filters["page_no"] = newPage + 1;
        this.setState({
            page: newPage + 1,
            submissionFilters: this.state.tabName === "Submissions" ? filters : this.state.submissionFilters,
            projectFilters: this.state.tabName === "Projects" ? filters : this.state.projectFilters,
            dealsFilters: this.state.tabName === "Deals" ? filters : this.state.dealsFilters,
            weekendReadFilters: this.state.tabName === "Weekend Read" ? filters : this.state.weekendReadFilters
        }, () => {
            this.pickMethod(this.state.tabName)
        });
    }

    handleSortColumn = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleTableSort = (colName) => {
        let isSortByAsc = this.state.isSortByAsc;
        isSortByAsc = this.state.sortCount % 2 === 0 ? true : false,
            this.setState({
                isSortByAsc,
                config: updateTableSortConfigObject(this.state.config, isSortByAsc, this.state.sortBy, colName)
            }, () => { this.filterMethod(colName) })
    }

    filterMethod = (colName) => {
        let filters = (this.state.tabName === "Submissions" ? this.state.submissionFilters : this.state.tabName === "Projects" ? this.state.projectFilters : this.state.tabName === "Deals" ? this.state.dealsFilters : this.state.tabName === "Weekend Read" ? this.state.weekendReadFilters : null)
        filters["order_by"] = this.state.isSortByAsc === true ? "ASC" : "DESC";
        filters["order_by_clause"] = colName;
        this.setState({
            sortBy: colName,
            submissionFilters: this.state.tabName === "Submissions" ? filters : this.state.submissionFilters,
            projectFilters: this.state.tabName === "Projects" ? filters : this.state.projectFilters,
            dealsFilters: this.state.tabName === "Deals" ? filters : this.state.dealsFilters,
            weekendReadFilters: this.state.tabName === "Weekend Read" ? filters : this.state.weekendReadFilters
        }, () => this.pickMethod(this.state.tabName))
    }

    navigationCallback = (obj, node) => {
        if (this.state.tabName === "Submissions") {
            this.props.history.push("/submissionDetails/" + obj.submission_id);
        } else if (this.state.tabName === "Projects") {
            this.props.history.push("/projectDetails/" + obj.project_id);
        } else if (this.state.tabName === "Deals") {
            this.props.history.push("/dealDetails/" + obj.deal_memo_id);
        } else if (this.state.tabName === "Weekend Read") {
            this.props.history.push("/weekendReadDetails/" + obj.weekend_read_id);
        }
    }

    getQueueList = (entity) => {
        this.setState({ isLoadingQueueData: true })
        ScripterService.getData(Constants.scripterServiceBaseUrl + `/queue?entity=${entity}`, 
        this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data.error) {
                    this.setState({ isLoadingQueueData: false })
                    console.log("Queue api Failed")
                } else {
                    this.setState({ 
                        queueList: response.data, 
                        isLoadingQueueData: false 
                    })
                }
            },
                (err) => {
                    console.log("Error in fetching Project List:", err)
                })
    }


    openNewTab = (value) => {
        switch (value.module_type) {
            case 'Project':
                let url = '/projectDetails/' + value.project_id
                return this.props.history.push(url);
            // return window.open(url);
            case 'Deal':
                let dealUrl = '/dealDetails/' + value.deal_id
                return this.props.history.push(dealUrl);
            // return window.open(dealUrl)
            case 'Weekend Read':
                let weekendUrl = 'weekendReadDetails/' + value.weekend_read_id
                return this.props.history.push(weekendUrl);
            // return window.open(weekendUrl)
            case 'Submission':
                let submissionUrl = 'submissionDetails/' + value.submission_id
                return this.props.history.push(submissionUrl);
            // return window.open(submissionUrl)
            case 'Coverage':
                let submissionCoverageUrl = `submissionDetails/${value.submission_id}?coverage=${value.submission_coverage_id}`
                return this.props.history.push(submissionCoverageUrl);
            // return window.open(submissionCoverageUrl)
        }
    }

    manageMessageModal = (mode, value, refreshValue) => {
        switch (mode) {
            case 'Success':
                this.setState({ showSuccess: value, showFail: false, refreshPage: refreshValue })
                break;
            case 'Fail': this.setState({ showSuccess: false, showFail: value })
                break;
            case 'Error': this.setState({ showSuccess: false, showFail: false, showError: value })
                break;
        }
    }

    removeFromQ = () => {
        this.setState({ confirmDelete: false })
        let projectDeal = JSON.parse(JSON.stringify({ ...this.state.projectAndDeal }));
        if (this.state.selectedQueue?.module_type === "Project") {
            projectDeal.is_project_queue = 1
            projectDeal.is_delete = 1
            projectDeal.project_id = this.state.selectedQueue?.project_id
            projectDeal.user_queue_project_id = this.state.selectedQueue?.user_queue_project_id
        } else if (this.state.selectedQueue?.module_type === "Deal") {
            projectDeal.is_deal_queue = 1
            projectDeal.is_delete = 1
            projectDeal.deal_memo_id = this.state.selectedQueue?.deal_id
            projectDeal.user_queue_deal_id = this.state.selectedQueue?.user_queue_deal_id
        } else if (this.state.selectedQueue?.module_type === 'Coverage' || this.state.selectedQueue?.module_type === 'Submission') {
            projectDeal.is_submission_queue = 1
            projectDeal.is_delete = 1
            projectDeal.submission_id = this.state.selectedQueue?.submission_id
            projectDeal.user_queue_submission_id = this.state.selectedQueue?.user_queue_submission_id
        } else if (this.state.selectedQueue?.module_type === "Weekend Read") {
            projectDeal.is_weekend_read_queue = 1
            projectDeal.is_delete = 1
            projectDeal.weekend_read_id = this.state.selectedQueue?.weekend_read_id
            projectDeal.user_queue_weekend_read_id = this.state.selectedQueue?.user_queue_weekend_read_id
        }

        this.setState({ projectAndDeal: projectDeal })
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + `/userQueue`, projectDeal, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data?.error) {
                    this.manageMessageModal("Fail", true);
                    return;
                }
                this.manageMessageModal("Success", true, true);
                this.getQueueList(this.state.queueFilterId)
            }, err => {
                this.manageMessageModal("Fail", true);
                console.log("Error from Deal Queue", err)
            })
    }

    confirmRemove = (value) => {
        this.setState({
            confirmDelete: true,
            selectedQueue: value
        })
    }

    getGroups = () => {
        let response = ScripterService.getData(Constants.scripterServiceBaseUrl + '/staticData?staticTable=group', null)
            .then(response => {
                let filteredSubmittedToGroups = response.data?.map(item => {
                    if (item?.name !== "Story Analyst" && item?.name !== "User Provisioner") {
                        return item?.name
                    }
                }).filter(item => item !== undefined);
                this.setState({
                    submittedToGroupName: '"' + filteredSubmittedToGroups.join('","') + '"'
                })
            },
                (err) => {
                    console.log("Error in fetching Group Details:", err)
                })
        return response
    }
    render() {
        let projectQueueList = this.state.queueList.filter(item => item.module_type === "Project");
        let dealQueueList = this.state.queueList.filter(item => item.module_type === "Deal");
        let weekendList = this.state.queueList.filter(item => item.module_type === "Weekend Read");
        let submissionList = this.state.queueList.filter(item => item.module_type === "Submission" || item?.module_type === "Coverage" );
        // submissionList = submissionList?.sort(function (a, b) {
        //     return new Date(b.due_date) - new Date(a.due_date);
        // });
        submissionList.sort((a,b)=>{
            if(a.pending_action===b.pending_action){
                return new Date(a.due_date) - new Date(b.due_date);
            
            }else{
                return b.pending_action-a.pending_action
            }
        });
        let message = this.state.confirmDelete ? this.state.confirmDeleteMsg : this.state.showCols ? this.state.maxColErrorMessage : this.state.showMinCols ? this.state.minColErrorMessage : "";
        let comfirmModalTitle = this.state.showCols ? this.state.showMaxCols : this.state.showMinCols ? this.state.showMinColsAlert : "";
        return (
            <>
                <div>
                    <div className="Landing-Page">
                        <div>
                            <h5 className="header">
                                <b>{this.state.user_name ? "Welcome, " + this.state.user_name : ""}</b>
                            </h5>
                        </div>
                        <div className="tabs">
                            <Tabs
                                orientation="horizontal"
                                variant="standard"
                                value={this.state.tabValue}
                                onChange={this.handleTabChange}
                                textColor="inherit"
                                indicatorColor="innherit"
                            >
                                {
                                    this.state.tabList?.map(item => <Tab className="tab" label={item.label} value={item.index} {...this.a11yProps(item.index)} />)
                                }
                            </Tabs>
                        </div>
                        <div>
                            <Loader isOpen={this.state.tabName === "Submissions" ? this.state.isFetchingSubmissionData:this.state.isFetchingData || false} text={"Loading..."} />
                            {this.state.tabName !== "My Queue" ?

                                <div
                                    id="landing-table"
                                    className={`${this.state.tabName === "Weekend Read" ? "weekendReadLastChild " :
                                        (this.state.tabName != "Submissions" && this.state.tabName != "My Queue") ?
                                            "lastColumnPadding" : ""} SubmissionsTable`
                                    }
                                >
                                    {this.state.tabName === "Submissions" ? 
                                        <div className="historical-data">
                                            <div></div>
                                            <BasicCheckbox 
                                                id={"historic-data"} 
                                                label={"Show Historical Data"}
                                                checked={this.state.showHistoricalData} 
                                                onChange={(e) => this.handleShowHistoricalData(e)} 
                                            />
                                        </div> : null
                                    }
                                    <TableComponent
                                        list={this.state.list ? this.state.list : []}
                                        config={this.state.config}
                                        options={this.state}
                                        isLandingPage={true}
                                        onColumnCheck={this.onColumnCheck.bind(this)}
                                        applyFilter={this.applyFilterLandingPage.bind(this)}
                                        tabValue={this.state.tabValue}
                                        tabName={this.state.tabName}
                                        autoCompleteCallBack={this.getAutoCompleteValues}
                                        getCheckBoxValues={this.getCheckBoxValues}
                                        hyperLinkNavigationCallback={this.navigationCallback}
                                        confidentialCheck={this.state.check}
                                        tabChange={this.state.tabChange}
                                        clearTabChange={this.clearTabChange}
                                        selectOptions={this.state.selectOptions}
                                        sortCallback={this.handleSortColumn}
                                    />
                                    <TablePagination
                                        component="div"
                                        count={this.state.currentList}
                                        page={this.state.page - 1}
                                        onChangePage={this.handleChangePage}
                                        rowsPerPage={this.state.rowsPerPage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                    <MessageModal
                                        open={this.state.showCols || this.state.showMinCols || false}
                                        title={comfirmModalTitle}
                                        message={message}
                                        hideCancel={this.state.showCols || this.state.showMinCols || false}
                                        primaryButtonText={"OK"}
                                        secondaryButtonText={"Cancel"}
                                        onConfirm={() => this.setState({ showCols: false, showMinCols: false })}
                                        handleClose={(e) => this.setState({ showCols: false, showMinCols: false })} />
                                </div>
                                :
                                <div className="bg-white">
                                    <Loader isOpen={this.state.isLoadingQueueData} text={"Loading..."} />
                                    <MDBRow>
                                        <MDBCol md={2} className="my-queue-filter">
                                            <SelectField
                                                id={"my-queue-filter"}
                                                defaultMenuText={"-Select-"}
                                                options={queueFilters || []}
                                                value={this.state.queueFilterId || ""}
                                                onChange={(e, value) => {
                                                    this.setState({ queueFilterId: e.target.value },
                                                        this.getQueueList(e.target.value))
                                                }}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    
                                    {this.state.queueFilterId === "PROJECTS" ? projectQueueList?.map((element) => (
                                        <div>
                                            <div className="Container">
                                                <div className="MyQueue">
                                                    <div className='d-flex justify-content-between'>
                                                        <div id="shareIcon">
                                                            <span className='f1'>{element.project_title}</span>
                                                            <MDBIcon
                                                                className="cursor-pointer ml2"
                                                                aria-controls="simple-menu"
                                                                aria-haspopup="true"
                                                                color={"primary"}
                                                                fontSize={10}
                                                                onClick={() => this.openNewTab(element)}
                                                                icon={"share"}
                                                            />
                                                        </div>
                                                        <div>
                                                            <span className='f9'>Status: {element.project_status_name}</span>
                                                        </div>
                                                    </div>
                                                    <MDBRow className='mt2'>
                                                        <MDBCol md={2} className='f9'>
                                                            <span className='mainLable'>AKA: </span ><span className='user_detail'>{element?.aka || "-"}</span><br />
                                                        </MDBCol>
                                                        <MDBCol md={3} className="lh2 f9">
                                                            <span className='mainLable'>Distribution Company: </span><span className='user_detail'>{element?.distribution_company_name || "-"}</span><br />
                                                        </MDBCol>
                                                        <MDBCol md={5} className='f9'>
                                                            <span className='mainLable'>Logline: </span><span className='user_detail'>{element?.log_line || "-"}</span><br />
                                                        </MDBCol>
                                                        <MDBCol md={2} className="align-end">
                                                            <span className='status fseptanary'>{element?.is_executive  ? 'Assigned Executive' : ''}</span><br />
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol md={2} className='f9'>
                                                            <span className='mainLable'>Type: <span className='user_detail'>{element?.project_type_name || "-"}</span></span>
                                                        </MDBCol>
                                                        <MDBCol md={3} className='f9'>
                                                            <span className='mainLable'>Genre: <span className='user_detail'>{element?.genre_name || "-"}</span></span>
                                                        </MDBCol>
                                                        <MDBCol md={5}>
                                                        </MDBCol>
                                                        <MDBCol md={2} className="align-end">
                                                            <a><span className='status fprimary' onClick={() => this.confirmRemove(element)}>
                                                                <span className='cursor'>Remove from the Queue</span>
                                                            </span>
                                                            </a>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </div>
                                            </div>
                                        </div>
                                    )) : null}
                                    {this.state.queueFilterId === "DEALS" ? dealQueueList.map((element) => (
                                        <div className="Container">
                                            <div className="MyQueue">
                                                <div className='d-flex justify-content-between'>
                                                    <div id="shareIcon">
                                                        <span className='f1'>{element.document_name}</span>
                                                        <MDBIcon
                                                            className="cursor-pointer ml2"
                                                            aria-controls="simple-menu"
                                                            aria-haspopup="true"
                                                            color={"primary"}
                                                            onClick={() => this.openNewTab(element)}
                                                            icon={"share"}
                                                        />
                                                    </div>
                                                    <div>
                                                        <span className='f1'>{element.module_type}</span>
                                                    </div>
                                                </div>
                                                <MDBRow className='mt2'>
                                                    <MDBCol md={2} className='f9'>
                                                        <span className='mainLable'>Memo Date: </span> <span className='user_detail'>{element?.memo_date ? getFormattedDate(element?.memo_date, false) : "-"}</span><br />
                                                    </MDBCol>
                                                    <MDBCol md={4} className='f9'>
                                                        <span className='mainLable'>Assigned Material: </span> <span className='user_detail'>{element?.assigned_material || "-"}</span><br />
                                                    </MDBCol>
                                                    <MDBCol md={4} className='f9'>
                                                        <span className='mainLable'>Notes: </span> <span className='user_detail'>{element?.deal_notes || "-"}</span><br />
                                                    </MDBCol>
                                                    <MDBCol md={2} className="align-end">
                                                        <span className='status fseptanary'>{element.deal_status}</span><br />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol md={6} className='f9'>
                                                        <span className='mainLable'>Amendments: </span> <span className='user_detail'>{element?.ammendment_date || "-"}</span>

                                                    </MDBCol>
                                                    <MDBCol md={4}>
                                                    </MDBCol>
                                                    <MDBCol md={2} className="align-end">
                                                        <a><span className='status fprimary' onClick={() => this.confirmRemove(element)}>
                                                            <span className='cursor'>Remove from the Queue</span>
                                                        </span>
                                                        </a>
                                                    </MDBCol>
                                                </MDBRow>
                                            </div>
                                        </div>
                                    )) : null}
                                    {this.state.queueFilterId === "WEEKEND_READS" ? weekendList.map((element) => (
                                        <div className="Container">
                                            <div className="MyQueue">
                                                <div className='d-flex justify-content-between'>
                                                    <div id="shareIcon">
                                                        <span className='f1'>{element.title}</span>
                                                        <MDBIcon
                                                            className="cursor-pointer ml2"
                                                            aria-controls="simple-menu"
                                                            aria-haspopup="true"
                                                            color={"primary"}
                                                            onClick={() => this.openNewTab(element)}
                                                            icon={"share"}
                                                        />
                                                    </div>
                                                    <div>
                                                        <span className='f1'>{element?.is_assigned ? 'Assigned' : ''}</span>
                                                    </div>
                                                </div>
                                                <MDBRow>
                                                    <MDBCol md={2} className='f9'>
                                                        <span className='mainLable'> WER Date: </span><span className='user_detail'>{element?.weekend_read_date ? getFormattedDate(element?.weekend_read_date, false) : "-"}</span><br />
                                                    </MDBCol>
                                                    <MDBCol md={4} className='f9'>
                                                        <span className='mainLable'>Attached Coverages: </span> <span className='user_detail'>{element?.attached_coverages || "-"}</span><br />
                                                    </MDBCol>
                                                    <MDBCol md={4} className='f9'>
                                                        <span className='mainLable'>Per/Advocate: </span><span className='user_detail'>{element?.advocate || "-"}</span><br />
                                                    </MDBCol>
                                                    <MDBCol md={2} className="align-end">
                                                        <span className='status fseptanary'>{element.wr_status}</span><br />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    
                                                    <MDBCol md={2} className='f9'>
                                                        <span className='mainLable' >Submitted by: </span> <span className='user_detail'>{element.submitted_by}</span>
                                                    </MDBCol>
                                                    <MDBCol md={4}></MDBCol>
                                                    <MDBCol md={4} className='f9'>
                                                        <span className='mainLable'>Additional Material: </span> <span className='user_detail'>{element.additional_material}</span>
                                                    </MDBCol>
                                                    <MDBCol md={2} className="align-end">
                                                        <a><span className='status fprimary' onClick={() => this.confirmRemove(element)}>
                                                            <span className='cursor'>Remove from the Queue</span>
                                                        </span>
                                                        </a>
                                                    </MDBCol>
                                                </MDBRow>
                                            </div>
                                        </div>
                                    )) : null}
                                    {this.state.queueFilterId === "SUBMISSIONS" ? submissionList.map((element) => (
                                        <div className="Container">
                                            <div className="MyQueue">
                                                <div className='d-flex justify-content-between'>
                                                    <div id="shareIcon">
                                                        <span className='f1'>{element.title}</span>
                                                        <MDBIcon
                                                            className="cursor-pointer ml2"
                                                            aria-controls="simple-menu"
                                                            aria-haspopup="true"
                                                            color={"primary"}
                                                            onClick={() => this.openNewTab(element)}
                                                            icon={"share"}
                                                        />
                                                    </div>
                                                    <div>
                                                        <span className='f1'>{element.module_type}</span>
                                                    </div>
                                                </div>
                                                <MDBRow>
                                                    <MDBCol md={4} className='f9'>
                                                        <span className='mainLable'> Author: </span><span className='user_detail'>{element?.author || "-"}</span><br />
                                                        {/* <MDBRow>
                                                            <MDBCol className='mainLable'>Author</MDBCol>
                                                            <MDBCol></MDBCol>
                                                        </MDBRow> */}
                                                    </MDBCol>
                                                    <MDBCol md={2} className='f9'>
                                                        <span className='mainLable'> Rcv. Date: </span><span className='user_detail'>{getFormattedDate(element?.received_date, false) || "-"}</span><br />
                                                    </MDBCol>
                                                    <MDBCol md={2} className='f9'>
                                                        <span className='mainLable'> Coverage Type: </span><span className='user_detail'>{element?.coverage_type_name || "-"}</span><br />
                                                    </MDBCol>
                                                    <MDBCol md={2} className='f9'>
                                                        <span className='mainLable'> Sent Date: </span><span className='user_detail'>{getFormattedDate(element?.sent_date, false) || "-"}</span><br />
                                                    </MDBCol>
                                                    <MDBCol md={2} className="align-end">
                                                        <span className='status fseptanary'>{element.submission_status_name ? element.submission_status_name : element.coverage_status}</span><br />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol md={4} className='f9'>
                                                        <span className='mainLable'> Submitted To: </span><span className='user_detail'>{element?.submitted_to || "-"}</span><br />
                                                    </MDBCol>
                                                    <MDBCol md={2} className='f9'>
                                                        <span className='mainLable'> Format: </span><span className='user_detail'>{element?.format || "-"}</span><br />
                                                    </MDBCol>
                                                    <MDBCol md={2} className='f9'>
                                                        <span className='mainLable'> Assigned Analyst: </span><span className='user_detail'>{element?.assigned_to || "-"}</span><br />
                                                    </MDBCol>
                                                    <MDBCol md={2} className='f9'>
                                                        <span className='mainLable'> Due Date: </span><span className='user_detail'>{getFormattedDate(element?.due_date, false) || "-"}</span><br />
                                                    </MDBCol>
                                                    <MDBCol md={2} className="align-end">
                                                        {!element?.pending_action ? 
                                                            <a><span className='status fprimary' onClick={() => this.confirmRemove(element)}>
                                                                    <span className='cursor'>Remove from the Queue</span>
                                                                </span>
                                                            </a> : 
                                                            <span className='status fseptanary'>{"Pending Action"}</span>
                                                        }
                                                    </MDBCol>
                                                </MDBRow>
                                            </div>
                                        </div>
                                    )) : null}
                                </div>
                            }
                        </div>
                    </div>
                    <MessageModal
                        open={this.state.confirmDelete || false}
                        title={"Delete From Queue"}
                        message={message}
                        hideCancel={false}
                        primaryButtonText={"Delete"}
                        secondaryButtonText={"Cancel"}
                        onConfirm={() => this.removeFromQ()}
                        handleClose={(e) => this.setState({ confirmDelete: false })}
                    />
                </div>
            </>
        )
    }
}));